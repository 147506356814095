import Router from 'next/router';
import React, { useEffect, useState } from 'react';

import { getIsAuthenticated } from '@src/utils/auth/getIsAuthenticated';

function withoutAuthentication<T extends React.Component>(Component: React.ComponentType<T>) {
  const WithOutAuthentication = (props: T) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      setIsAuthenticated(getIsAuthenticated());
      setLoading(false);
    }, []);

    useEffect(() => {
      if (!loading && isAuthenticated) {
        Router.push('/marketplace');
      }
    }, [loading, isAuthenticated]);

    return <>{!isAuthenticated && <Component {...props} />}</>;
  };

  return WithOutAuthentication;
}

export default withoutAuthentication;
