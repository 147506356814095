import * as cx from 'classnames';
import * as React from 'react';
import { forwardRef } from 'react';

import { THEME, useCurrentTheme } from '@api/local/theme';
import CheckMarkBlue from '@icons/ui-kit/CheckMarkBlue.svg';
import CheckMarkIndeterminate from '@icons/ui-kit/CheckMarkIndeterminate.svg';
import CheckMarkIndeterminateWhite from '@icons/ui-kit/CheckMarkIndeterminateWhite.svg';
import CheckMarkWhite from '@icons/ui-kit/CheckMarkWhite.svg';

// Styles
import s from './Checkbox.module.sass';

// Props
interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  text?: string;
  indeterminate?: boolean;
  theme?: 'big';
}

// eslint-disable-next-line react/display-name
const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ id, text, indeterminate, className, theme, ...props }, ref) => {
    const colorTheme = useCurrentTheme();
    const isLightTheme = colorTheme.data?.theme === THEME.light;

    return (
      <label
        htmlFor={id}
        className={cx(s.label, { [s.indeterminate]: indeterminate }, { [s.big]: theme === 'big' })}
      >
        {text}
        <input ref={ref} {...props} className={cx(s.input, className)} id={id} type="checkbox" />
        <span className={s.checkmark}>
          <span className={s.checkmarkFull}>
            {isLightTheme ? <CheckMarkBlue /> : <CheckMarkWhite />}
          </span>
          {indeterminate && (
            <span className={s.checkmarkIndeterminate}>
              {isLightTheme ? <CheckMarkIndeterminate /> : <CheckMarkIndeterminateWhite />}
            </span>
          )}
        </span>
      </label>
    );
  },
);

export default Checkbox;
