import { GetStaticProps } from 'next';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import React, { useState } from 'react';

import Login from '@components/authentication/Login';
import LayoutPhoto from '@components/layout/LayoutPhoto';
import { SITE_URL } from '@src/config';
import withoutAuthentication from '@src/hoc/withoutAuthentication';
import Meta from '@ui/Meta/Meta';
import TwoFA from '@ui/TwoFA';
import '@ui/ToastMessage';

type TwoFARequestInfo = {
  email: string;
  password: string;
  remember?: boolean;
};

const IndexPage = () => {
  const [twoFARequestInfo, setTwoFARequestInfo] = useState<TwoFARequestInfo | undefined>();

  const { i18n } = useTranslation();

  const canonicalUrl = `${SITE_URL}${i18n.language === 'en' ? '' : `/${i18n.language}`}/login`;

  return (
    <LayoutPhoto>
      <Meta canonicalUrl={canonicalUrl} title="Login" />

      {twoFARequestInfo ? (
        <TwoFA {...twoFARequestInfo} />
      ) : (
        <Login onRequested2FA={setTwoFARequestInfo} />
      )}
    </LayoutPhoto>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale as string, ['login', 'common', 'settings'])),
  },
});

export default withoutAuthentication(IndexPage);
