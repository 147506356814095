import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { toast } from 'react-toastify';

import { loginUser } from '@src/api/Api';
import { UserRole } from '@src/api/models/enums';
import { User } from '@src/api/models/interfaces';
import Input from '@ui/Input';
import Button from '@ui/button/Button';
import Checkbox from '@ui/checkbox/Checkbox';
import CustomLink from 'src/ui-kit/customLink/CustomLink';
import { composeValidators, minLength, required } from 'src/utils/validators';

import styles from './Login.module.sass';
import notificationStyles from './provide-data-message.module.sass';

type LoginProps = {
  onRequested2FA: (arg: { email: string; password: string; remember?: boolean }) => void;
};

type Values = {
  email: string;
  password: string;
  remember?: boolean;
};

export const ProvideDataMessage = () => {
  const { t } = useTranslation('common');

  return (
    <div className={notificationStyles.notificationInner}>
      <div className={notificationStyles.notificationText}>
        <p>{t('common:Notation.provideData')}</p>
      </div>
      <div className={notificationStyles.notificationButtons}>
        <div className={notificationStyles.buttonStock}>
          <Button theme="middle" link="/profile">
            {t('common:Notation.provideDataLink')}
          </Button>
        </div>
      </div>
    </div>
  );
};

const Login: React.FC<LoginProps> = ({ onRequested2FA }) => {
  const { t, i18n } = useTranslation(['login', 'common']);

  const router = useRouter();
  const [authenticateError, setAuthenticateError] = useState(false);

  const onSubmit = async (values: Values) => {
    const { data, errors } = await loginUser(values);

    const prevPath = router.query.from as string | undefined;

    if (errors || !data) {
      setAuthenticateError(true);
    } else {
      if ((data as { is2FARequired: boolean }).is2FARequired) {
        onRequested2FA(values);
      } else if (
        (data as User).role === UserRole.USER ||
        (data as User).role === UserRole.SELLER ||
        (data as User).role === UserRole.ADMIN
      ) {
        if (prevPath) {
          await router.push(prevPath);
        } else {
          await router.push('/marketplace');
        }

        if ((data as User).first_name.length === 0 || (data as User).last_name.length === 0) {
          toast(<ProvideDataMessage />);
        }
      } else {
        setAuthenticateError(true);
      }
    }

    return undefined;
  };

  return (
    <>
      <p className={styles.description}>{t('Login.description')}</p>
      <Form
        key={i18n.language}
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, pristine, valid, modifiedSinceLastSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Field name="email" validate={composeValidators(required)}>
              {({ input, meta }) => (
                <div className={styles.input}>
                  <Input
                    {...input}
                    type="email"
                    placeholder={t('common:Inputs.email.label')}
                    active={meta.active}
                    error={(meta.touched && meta.error) || meta.submitError}
                    success={!meta.error && meta.touched}
                  />
                </div>
              )}
            </Field>
            <Field name="password" validate={composeValidators(required, minLength(4))}>
              {({ input, meta }) => (
                <div className={styles.input}>
                  <Input
                    {...input}
                    type="password"
                    placeholder={t('common:Inputs.password.placeholder')}
                    active={meta.active}
                    error={(meta.touched && meta.error) || meta.submitError}
                  />
                </div>
              )}
            </Field>
            <Field name="remember" type="checkbox">
              {({ input, meta }) => (
                <div className={styles.checkbox}>
                  <Checkbox {...input} text={t('common:Inputs.remember')} />
                  <div>
                    <CustomLink link="/forgot-password">{t('Login.forgot')}</CustomLink>
                  </div>
                  {meta.error && meta.touched && <span>{meta.error}</span>}
                </div>
              )}
            </Field>
            <div className={styles.button}>
              <Button
                disabled={submitting || pristine || (!valid && !modifiedSinceLastSubmit)}
                type="submit"
              >
                {submitting
                  ? t('common:Buttons.login.processing')
                  : t('common:Buttons.login.default')}
              </Button>
            </div>
            {authenticateError && <div className={styles.error}>{t('Login.noAccount')}</div>}
          </form>
        )}
      />
      <p className={styles.additional}>
        {t('Login.new')}
        <CustomLink link="signup">{t('Login.signUp')}!</CustomLink>
      </p>
    </>
  );
};

export default Login;
