import { User } from '@sentry/nextjs';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { toast } from 'react-toastify';

import { ProvideDataMessage } from '@components/authentication/Login';
import { confirm2FALogin } from '@src/api/Api';
import { UserRole } from '@src/api/models/enums';
import { useLoading } from '@src/hooks/useLoading';
import Input from '@ui/Input';
import Button from 'src/ui-kit/button/Button';
import { extractFieldSingleError } from 'src/utils/api';
import { required } from 'src/utils/validators';

import s from './TwoFA.module.sass';

type TwoFAProps = {
  email: string;
  password: string;
  remember?: boolean;
};

type Values = {
  code: string;
};

const TwoFA: React.FC<TwoFAProps> = ({ email, password, remember }) => {
  const { t, i18n } = useTranslation(['login', 'common']);
  const router = useRouter();

  const [, setIsLoading] = useLoading();

  if (typeof window === 'undefined') {
    return null;
  }

  const onSubmit = async (values: Values) => {
    setIsLoading(true);
    const { data, errors } = await confirm2FALogin({
      email,
      password,
      remember,
      code: values.code,
    });
    setIsLoading(false);

    if (errors) {
      return extractFieldSingleError(errors);
    }

    const prevPath = router.query.from as string | undefined;

    if (data && (data.role === UserRole.USER || data.role === UserRole.SELLER)) {
      if (prevPath) {
        await router.push(prevPath);
      } else {
        await router.push('/marketplace');
      }
    }

    if ((data as User).first_name.length === 0 || (data as User).last_name.length === 0) {
      toast(<ProvideDataMessage />);
    }

    return undefined;
  };

  return (
    <>
      <p className={s.identity} dangerouslySetInnerHTML={{ __html: t('2FA.firstLine') }} />
      <p className={s.description} dangerouslySetInnerHTML={{ __html: t('2FA.secondLine') }} />
      <Form
        key={i18n.language}
        onSubmit={onSubmit}
        render={({
          handleSubmit,
          submitting,
          pristine,
          valid,
          modifiedSinceLastSubmit,
          submitError,
        }) => (
          <form onSubmit={handleSubmit}>
            <Field name="code" validate={required}>
              {({ input, meta }) => (
                <div className={s.input}>
                  <Input
                    {...input}
                    type="text"
                    placeholder={t('common:Inputs.oneTime')}
                    active={meta.active}
                    error={(meta.touched && meta.error) || meta.submitError}
                    success={!meta.error && meta.touched}
                  />
                </div>
              )}
            </Field>
            <div className={s.button}>
              <Button
                type="submit"
                disabled={submitting || pristine || (!valid && !modifiedSinceLastSubmit)}
              >
                {submitting
                  ? t('common:Buttons.verify.processing')
                  : t('common:Buttons.verify.default')}
              </Button>
              {submitError && <div className={s.error}>{submitError}</div>}
            </div>
          </form>
        )}
      />
    </>
  );
};

export default TwoFA;
